import {
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from "@material-ui/core";
import { graphql } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { useState } from "react";
import { useEffect } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ProjectGallery from "../components/ui/ProjectGallery/ProjectGallery";
import { Images } from "../utils/findImage";

interface ProjectsPageProps {
  data: {
    projectGallery: Images;
    prismicProjectsPage: ProjectPageData;
  };
  location: Location;
}

// const projectCategories = [
//   "All",
//   "Custom homes",
//   "Kitchens",
//   "Bathrooms",
//   "Decks & Porches",
// ];

const ProjectsPage: React.FC<ProjectsPageProps> = ({ data, location }) => {
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const projectsPageData = data.prismicProjectsPage.data;

  const [projectsToDisplay, setProjectsToDisplay] = useState<Project[]>(
    projectsPageData.project_gallery.map((item) => item)
  );

  const projectCategories = [
    "All",
    ...projectsPageData.project_gallery.map(({ project }) => project.uid),
  ];

  const projectCategoriesTitles = [
    "All",
    ...projectsPageData.project_gallery.map(
      ({ project }) => project.document.data.title.text
    ),
  ];

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    handleProjectsToDisplay();
  }, [value]);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    _: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setValue(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProjectsToDisplay = () => {
    if (value === 0) {
      setProjectsToDisplay(
        projectsPageData.project_gallery.map((item) => item)
      );
    } else {
      setProjectsToDisplay(
        projectsPageData.project_gallery.filter(
          (item) => item.project.uid === projectCategories[value]
        )
      );
    }
  };

  return (
    <Layout>
      <SEO
        title={projectsPageData.seo[0].seo_title}
        description={projectsPageData.seo[0].seo_description}
        slug={location.pathname}
      />

      <section className="bg-gray-800">
        <div className="container">
          <h1 className="text-4xl text-white leading-none mb-0">Projects</h1>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="flex flex-wrap">
            <div className="w-full md:mb-8 mb-4">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
                className="hidden lg:block"
              >
                {projectCategoriesTitles.map((item, index) => (
                  <Tab key={`${item}-tab-${index}`} label={item} />
                ))}
              </Tabs>

              <div className="lg:hidden">
                <List component="nav" aria-label="Project categories">
                  <ListItem
                    button
                    aria-haspopup="true"
                    aria-controls="lock-menu"
                    aria-label="Select Project category"
                    onClick={handleClickListItem}
                    className="capitalize"
                  >
                    <ListItemText
                      primary="Project Category"
                      secondary={projectCategoriesTitles[value]}
                      className="capitalize"
                    />
                  </ListItem>
                  <Menu
                    id="lock-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {projectCategoriesTitles.map((item, index) => (
                      <MenuItem
                        key={`${item}-select-${index}`}
                        value={index}
                        selected={index === value}
                        onClick={(event) => handleMenuItemClick(event, index)}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Menu>
                </List>
              </div>
            </div>
            <div className="w-full">
              <ProjectGallery>
                {projectsToDisplay.map(({ project }) => {
                  return project.document.data.images.map(
                    ({ image }, index) => (
                      <div
                        key={`${project}-item-${image.localFile.name}-${index}`}
                        className="mb-4"
                      >
                        <GatsbyImage
                          image={
                            image.localFile.childImageSharp.gatsbyImageData
                          }
                          alt={image.alt || ""}
                        />
                      </div>
                    )
                  );
                })}
              </ProjectGallery>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ProjectsPage;

export const pageQuery = graphql`
  query {
    projectGallery: allFile(filter: { base: { regex: "/portfolio/" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90)
        }
      }
    }
    prismicProjectsPage {
      uid
      data {
        title {
          text
        }
        seo {
          seo_title
          seo_description
        }
        project_gallery {
          project {
            uid
            document {
              ... on PrismicProjectCategories {
                id
                data {
                  title {
                    text
                  }
                  images {
                    image {
                      alt
                      localFile {
                        name
                        childImageSharp {
                          gatsbyImageData(
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                            quality: 100
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

interface ProjectPageData {
  data: {
    title: {
      text: string;
    };
    seo: {
      seo_title: string;
      seo_description: string;
    }[];
    project_gallery: Project[];
  };
}

type Project = {
  project: {
    uid: string;
    document: {
      data: {
        title: {
          text: string;
        };
        images: {
          image: {
            alt: string;
            localFile: {
              name: string;
              childImageSharp: {
                gatsbyImageData: IGatsbyImageData;
              };
            };
          };
        }[];
      };
    };
  };
};
